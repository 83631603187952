import { Grid, Typography } from '@mui/material';
import { Formik } from 'formik';

import { useState } from 'react';
import SubmitButtonWrapper from 'components/Form/SubmitButtonWrapper';
import * as yup from 'yup';
import TextField from '../../Form/TextFieldWrapper';

const FORM_VALIDATION = yup
  .object()
  .shape({
    notes: yup.string()
  })
  .required();

const INITAL_FORM_STATE = {
  notes: ''
};

export function UserOdontogramNotesForm(props) {
  const [notes, setNotes] = useState();

  return (
    <Formik
      initialValues={{ notes: props.initialValue } || INITAL_FORM_STATE}
      validationSchema={FORM_VALIDATION}
      onSubmit={(_values) => {
        props.onSubmit(_values.notes);
      }}
    >
      {({ errors }) => (
        <>
          <Typography variant="h5" gutterBottom pt={3}>
            Notas del odontodiagrama
          </Typography>

          <Grid
            container
            rowSpacing={5}
            columnSpacing={3}
            columns={{ xs: 6, sm: 6, md: 12 }}
            p={{ xs: 2, sm: 3 }}
          >
            <Grid item xs={12}>
              <TextField name="notes" multiline rows={10} variant="outlined" />
            </Grid>
          </Grid>
          <SubmitButtonWrapper disabled={Object.keys(errors).length > 0}>
            Gardar notas
          </SubmitButtonWrapper>
        </>
      )}
    </Formik>
  );
}
